.sv-badge {
  display: inline-block;
  padding: 2px 7px;
  font-size: 10px;
  font-weight: bold;
  line-height: 12px;
  color: #ffffff;
  vertical-align: baseline;
  white-space: nowrap;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #999999;
  border-radius: 9px;
}

.sv-admin-badge-container-small {
  position: relative;
  float: left;
   margin-right:0.5em
}

.sv-admin-badge-container-large {
  position: relative;
   width:48px
}

.sv-list-5-columns .sv-admin-badge-container-large {
   margin:0 auto
}

.sv-list-2-columns .sv-admin-badge-container-large {
  float: left;
   margin-right:1em
}

.sv-admin-badge:after {
  display: block;
  position: absolute;
  content: url('../static/img/wrench_16_2.png');
  width: 16px;
  height: 16px;
  right: 0px;
   bottom: 2px
}

.sv-admin-badge-mini:after {
  display: block;
  position: absolute;
  content: url('../static/img/wrench_14.png');
  width: 14px;
  height: 14px;
  right: 0px;
   bottom: 2px
}

.sv-like i {
   margin-right:3px
}

.sv-new:before {
   content:"";
  display: inline-block;
  width: 10px;
  height: 10px;
   margin-right:.5em;
   background-color:#AAA;
   border-radius:10px
}

/* Standard breadcrumb */
.sv-breadcrumb {
  background-color: transparent;
  margin: 0;
}

.sv-breadcrumb a,
.sv-breadcrumb li {
   font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  text-decoration: none;
   color:#0088cc
}

.sv-breadcrumb li.active {
   color: #555555
}

.sv-polaroid {
   padding: 4px;
   background-color:#FFF;
   border:1px solid #CCC;
   border: 1px solid rgba(0, 0, 0, 0.2);
   box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
   border-radius:3px
}

.sv-empty-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
   line-height: 14px
}

/* Used by calendar-portlet and activities-portlet */
.popover {
   word-wrap: break-word
}

.sv-notifier {
  position: fixed;
  top: 50px;
  width: 450px;
  left: 50%;
  margin-left: -225px;
  z-index: 3000;
}

@media screen and (max-width: 580px) {
  .sv-notifier {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0;
  }
}

.bootstrap.modal .modal-header h3 {
  font-size: 20px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  text-rendering: optimizelegibility;
  color: #333333;
}
